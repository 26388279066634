import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { ISubNavPage } from '@shared/core/components/subNav';
import { Observable, of, combineLatest as ObservableCombineLatest } from 'rxjs';
import { map, catchError, distinctUntilChanged, auditTime, filter, combineLatest, withLatestFrom, switchMap, take, tap, pairwise } from 'rxjs/operators';
import { ILocationBusinessModel } from '@shared/state';

@Injectable({
    providedIn: 'root'
})
export class RoutesController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
        private _queryParamsService: Services.QueryParamsService,
        private _cryptoService: Services.CryptoService,
        private _routeService: Services.RouteService,
        private _modalsService: Services.ModalsService,
        private _creditCardsService: Services.CreditCardsService,
    ) { }
    public navigateToLocation(locationNo: number): Promise<boolean> {
        return this._routeService.navigateToLocation(locationNo);
    }

    public navigateToLocationsSearchView(): Promise<boolean> {
        return this._routeService.navigateToLocationsSearchView();
    }

    public navigateToCartsLocation(): Promise<boolean> {
        return this._routeService.navigateToCartsLocation();
    }

    public authAction(): void {
        this._store
            .pipe(
                select(selectors.isMemberAuthorizedJWT),
                take(1)
            ).subscribe(isAuthorized => {
                if (!isAuthorized) {
                    return this._modalsService.show({
                        type: 'auth'
                    });
                }

                if (this._config.appMode === IAppMode.ORDERING_ONLY) {
                    return this._routeService.navigateToProfileView();
                }

                return this._routeService.navigateToProfileLoyaltyHome();
            });
    }

    public navToHome(): Promise<boolean> {
        return this._routeService.navigateToHomeView();
    }

    public isHomePage$(): Observable<boolean> {
        return this._store.pipe(
            select(selectors.isCurrentRouteHome)
        );
    }

    public isLoyaltyHomePage$(): Observable<boolean> {
        return this._store.pipe(
            select(selectors.isCurrentRouteLoyalty)
        );
    }

    public isAppModeHomePage$(): Observable<boolean> {
        return ObservableCombineLatest(
            this.isHomePage$(),
            this.isLoyaltyHomePage$(),
        ).pipe(
            map(([orderingHome, loyaltyHome]) => orderingHome || loyaltyHome)
        );
    }

    public isCheckoutPage$(): Observable<boolean> {
        return this._store.pipe(
            select(selectors.isCheckoutPage)
        );
    }

    public isLocationsSearchPage$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.routeIsLocationsSearchPage(this._config)),
            );
    }

    public isLocationDetailsPage$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.routeIsLocationDetailsPage(this._config)),
            );
    }

    public isAccountPage$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.routeIsAccountPage())
            );
    }

    public isError404Page$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.routeIsErrorPage()),
            );
    }

    public isOrderConfirmationPage$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.routeIsOrderConfirmationPage()),
            );
    }

    public get accountPagesList(): ISubNavPage[] {
        const links: ISubNavPage[] = [
            {
                Id: 1,
                Name: 'Home',
                Url: '/account/loyalty',
            },
            {
                Id: 2,
                Name: 'Rewards',
                Url: '/account/rewards',
            },
            {
                Id: 3,
                Name: 'Program',
                Url: '/account/program',
            },
            {
                Id: 4,
                Name: 'Profile',
                Url: '/account'
            },
            {
                Id: 5,
                Name: 'Orders',
                Url: '/account/orders'
            },
            {
                Id: 6,
                Name: 'History',
                Url: '/account/history'
            },
            {
                Id: 7,
                Name: 'Password',
                Url: '/account/password'
            },
            {
                Id: 8,
                Name: 'Payment',
                Url: '/account/payment'
            },
        ];


        switch (true) {
            case this._config.appMode === IAppMode.ORDERING_ONLY:
                return links.filter(link => link.Id === 4 || link.Id === 5 || link.Id === 7 || link.Id === 8);

            case this._config.appMode === IAppMode.LOYALTY_ONLY:
                return links.filter(link => link.Id === 1 || link.Id === 2 || link.Id === 3 || link.Id === 4 || link.Id === 6 || link.Id === 7);

            default:
                return links.filter(link => link.Id !== 6);
        }
    }

    public accountPages$(pages: ISubNavPage[] = null, extraOptions: ISubNavPage[] = [], excludeWithIds: number[] = []): Observable<ISubNavPage[]> {
        return Observable.create(observer => {
            let commonPages: ISubNavPage[] = this.accountPagesList;

            if (pages) {
                commonPages = pages;
            }

            const filtered = [
                ...commonPages,
                ...extraOptions,
            ].filter(obj => !excludeWithIds.includes(obj.Id));

            observer.next(filtered);
        });
    }

    public accountPagesForUCP$(pages: ISubNavPage[] = null, extraOptions: ISubNavPage[] = [], excludeWithIds: number[] = [3]): Observable<ISubNavPage[]> {
        return this.accountPages$(pages, [...extraOptions, {
            Id: -1,
            Name: 'Sign out',
            Url: null,
        }], excludeWithIds)
            .pipe(
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.freeValidProducts)
                        )
                ),
                map(([p, freeValidProducts]) => p.map(page => {
                    if (page.Id === 2) {
                        return {
                            ...page,
                            Counter: freeValidProducts ? freeValidProducts.length : 0
                        };
                    }

                    return page;
                }).filter(page => page.Id !== 7 && page.Id !== 8))
            );
    }

    public mapAccountRouteUrlToId$(customMap: { [name: string]: { url: string; id: number; }; } = null): Observable<number> {
        /* This is a way to get active id for sub-nav component */
        const defaultRoutes: ISubNavPage[] = this.accountPagesList;
        const defaultMap = defaultRoutes.reduce((acc, route) => {
            let mappedName = route.Url.split('/')[2];
            if (!mappedName) {
                mappedName = 'profile';
            }
            acc[mappedName] = {
                id: route.Id,
                url: route.Url
            };

            return acc;
        }, {} as { [name: string]: { url: string; id: number; }; });

        const targetMap = customMap || defaultMap;

        return this._store
            .pipe(
                select(selectors.getCurrentRoute),
                combineLatest(
                    this.isAccountPage$(),
                ),
                filter(([route, isAccountPage]) => route !== null && isAccountPage === true),
                map(([route]) => {
                    let id: number = null;

                    Object.keys(targetMap).map(key => {
                        if (id !== null) return;

                        const obj = targetMap[key];

                        const foundMatch = route.url === obj.url;
                        if (foundMatch) {
                            id = obj.id;

                            return;
                        }
                    });

                    return id;
                })
            );
    }

    public canNavigateToCheckoutAuthorizedOnly$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.canNavigateToCheckoutAuthorizedOnly)
            );
    }

    public canNavigateToCheckoutUnauthorized$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.canNavigateToCheckoutUnauthorized)
            );
    }

    public getCurrentRouteQueryParams$(): Observable<{ [key: string]: string; }> {
        return this._store
            .pipe(
                select(selectors.getCurrentRouteQueryParams)
            );
    }

    public handlerPaymentGatewayReturnRedirect(): void {
        if(Utils.Storage.getItem(OLO.Enums.SESSION_STORAGE.VENDOR_PAYMENT_DETAILS_COLLECT, 'sessionStorage')) {
            this.handleVendorRedirect();
        } else {
            this.handleCreditCardsRedirect();
        }
    }

    public handleVendorRedirect(): void {


        this.getCurrentRouteQueryParams$()
            .pipe(
                auditTime(10),
                take(1),
                tap(() => {
                    this._store.dispatch(actions.StateRestore({
                        setProps: {
                            modals: []
                        }
                    }));
                }),
                withLatestFrom(
                    this._store
                        .pipe(
                            select(selectors.getPaymentState)
                        )
                ),
                map(([params, paymentState]) => {
                    const isWindcaveRedirec: boolean = this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.WINDCAVE;
                    if(isWindcaveRedirec) {
                        const status = params.status;
                        switch(status) {
                            case 'success':
                                return true;
                            case 'cancel':
                            case 'failed':
                                return false;
                        }
                    }

                    throw new Error('Vendor redirect from payment provider not handled:' + this._config.payments.baseProvider);
                }),
                catchError(ex => {
                    console.error(ex);

                    return of(false);
                })
            )
            .subscribe((isOk) => {
                Utils.Storage.remove(OLO.Enums.SESSION_STORAGE.VENDOR_PAYMENT_DETAILS_COLLECT, 'sessionStorage');
                Utils.Redirect.unsetRedirect();

                if(!isOk) {
                    this._store.dispatch(actions.PaymentStepFailed('Payment failed'));
                }

                this._routeService.navigateToCheckout(null)
                    .then(() => {
                        this._store.dispatch(actions.PaymentStepPay());
                    });
            });

    }

    public handleCreditCardsRedirect(): void {
        this.getPaymentGatewayStatusFromQueryParams$()
            .pipe(
                take(1),
                withLatestFrom(
                    this.getCurrentRouteQueryParams$()
                )
            )
            .subscribe(([status, params]) => this._creditCardsService.handleCardReturnRedirect(status, {
                token: null,
                r: null,
                v: null,
                ...params
            }).then(() => this._routeService.navigateToCheckout(null)));
    }

    public getPaymentGatewayStatusFromQueryParams$(): Observable<boolean> {
        /* null, true, false */
        return this.getCurrentRouteQueryParams$()
            .pipe(
                map(params => {


                    const isPaymentExpress: boolean = this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.PAYMENT_EXPRESS;
                    const isFatZebra: boolean = this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA;

                    if (!params || !params.status && isPaymentExpress) return null;

                    if (isFatZebra) {
                        return this._fatZebraStatus(params.r as OLO.Types.FAT_ZEBRA_STATUS);
                    }

                    if (isPaymentExpress) {
                        const decrypt: string = this._cryptoService.decrypt(params.status, false, null);
                        if (!decrypt || typeof decrypt !== 'string') return false;

                        const response = decrypt[decrypt.length - 1];

                        return response === '1' ? true : false;
                    }

                    throw new Error(`Payment provider not configured: ${this._config.payments.baseProvider}`);
                })
            );
    }

    private _fatZebraStatus(status: OLO.Types.FAT_ZEBRA_STATUS): boolean {
        switch (status) {
            case '1':
                return true;
            case '95':
                console.error('Merchant Username not found');

                return false;
            case '97':
                console.error('Validation error - there was an error validating the customers input (e.g. bad card number)');

                return false;
            case '99':
                console.error('Invalid Verification - the verification was not successful indicating the data may have been tampered with');

                return false;
            case '999':
                console.error('Gateway Error - contact support if this error persists');

                return false;
        }
    }

    public extractOrderConfirmationQueryParams$(): Observable<{ orderId: number; locationNo: number; }> {
        return this._store
            .pipe(
                select(selectors.getCurrentRouteQueryParams),
                map(params => {
                    if (!params || !params.order) return null;

                    return this._queryParamsService.decryptOrderData(params);
                })
            );
    }

    public extractLocationFromQueryParams$(): Observable<ILocationBusinessModel> {
        return this.extractOrderConfirmationQueryParams$()
            .pipe(
                switchMap(params => {
                    if (!params) return of(null);

                    return this._store
                        .pipe(
                            select(selectors.getLocationDetails(params.locationNo))
                        );
                })
            );
    }

    public extractLocationFriendlyNameFromQueryParams$(): Observable<string> {
        return this.extractLocationFromQueryParams$()
            .pipe(
                map(location => location ? location.LocationFriendlyName : null)
            );
    }

    public extractLocationFullAddressFromQueryParams$(): Observable<string> {
        return this.extractLocationFromQueryParams$()
            .pipe(
                map(location => {
                    if (!location) return null;
                    const arr: string[] = [];
                    if (location.StreetAddress) arr.push(location.StreetAddress);
                    if (location.Suburb) arr.push(location.Suburb);
                    if (location.PostCode) arr.push(location.PostCode);

                    return arr.join(', ');
                })
            );
    }

    public extractOrderDetailsFromQueryParams$(): Observable<State.IOnlineOrderStateItemObj> {
        return this.extractOrderConfirmationQueryParams$()
            .pipe(
                switchMap(params => {
                    if (!params) return of(null);

                    return this._store
                        .pipe(
                            select(selectors.getHistoryOrder(params.orderId))
                        );
                })
            );
    }

    public extractOrderIdFromQueryParams$(): Observable<number> {
        return this.extractOrderDetailsFromQueryParams$()
            .pipe(
                map(order => order ? order.OrderId || null : null)
            );
    }

    public extractLocationNoFromQueryParams$(): Observable<number> {
        return this.extractOrderConfirmationQueryParams$()
            .pipe(
                map(params => params ? params.locationNo : null)
            );
    }

    public extractOrderProductsFromQueryParams$(): Observable<OLO.Ordering.IOnlineOrderMappedProducts> {
        return this.extractOrderDetailsFromQueryParams$()
            .pipe(
                filter(order => order !== undefined && order !== null && order.data !== null),
                map(order => Utils.OnlineOrders.mapOnlineOrderProducts(order.data, true))
            );
    }

    public extractOrderSummaryFromQueryParams$(): Observable<OLO.Ordering.IOrderSummary> {
        return this.extractOrderDetailsFromQueryParams$()
            .pipe(
                filter(order => order !== undefined && order !== null && order.data !== null),
                map(order => ({
                    Subtotal: order.data.TotalNettValue,
                    Tax: order.data.TotalTaxes[0] ? order.data.TotalTaxes[0].Value : null,
                    Total: order.data.TotalGrossValue,

                }))
            );
    }

    public extractOrderWithLocationLoadingFromQueryParams$(): Observable<boolean> {
        return this.extractOrderDetailsFromQueryParams$()
            .pipe(
                combineLatest(
                    this.extractLocationFriendlyNameFromQueryParams$()
                ),
                map(([order, locationName]) => order === undefined || order === null || order.data === null || locationName === null || locationName === undefined)
            );
    }

    public extractOrderMemberIdFromQueryParams$(): Observable<number> {
        return this.extractOrderDetailsFromQueryParams$()
            .pipe(
                filter(order => order !== undefined && order !== null && order.data !== null),
                map(order => order.data.MemberId),
            );
    }

    public extractGUIDTokenFromQueryParams$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getCurrentRoute),
                filter(route => route !== null),
                map(route => {
                    if (route.queryParams.hasOwnProperty('guid')) {
                        return route.queryParams.guid;
                    }

                    return null;
                }),
            );
    }
}
