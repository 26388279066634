import {
    IGetMemberAccountBalanceResponse, ILoyaltyAppTransactionModel,
    IMemberAuthData,
    IMemberChangePasswordBusinessModel,
    IMemberEmailConfirmationRequestModel,
    IMemberForgottenPasswordResetModel,
    IMemberModel, ITransactionBusinessModel,
    IValidateMemberLoginRequest
} from '@shared/state';

export class MembersMapper {
    public static mapGetLoyaltyProductsForMemberRequest(loyaltyProductsForMember: APIv3.MembersGetLoyaltyProductProgramTrackings.Responses.$200):
    APICommon.IGetLoyaltyProductProgramTrackingBusinessModel[] {
        return loyaltyProductsForMember?.Items ? loyaltyProductsForMember?.Items.map((loyaltyProductProgram: APIv3.GetLoyaltyProductProgramTrackingBusinessModel) => ({
            Id: loyaltyProductProgram.Id,
            MemberId: loyaltyProductProgram.MemberId,
            MemberCardNumber: loyaltyProductProgram.MemberCardNumber,
            LoyaltyProductProgramId: loyaltyProductProgram.LoyaltyProductProgramId,
            CurrentProductCount: loyaltyProductProgram.CurrentProductCount,
            ProductIssuedCount: loyaltyProductProgram.ProductIssuedCount,
            ProgramName: loyaltyProductProgram.ProgramName,
            ProductDescription: loyaltyProductProgram.ProductDescription,
            PLU: loyaltyProductProgram.PLU,
        })) : null;
    }

    public static mapUserDataGETResponse(currentMember: APIv3.AuthGetCurrentMember.Responses.$200): IMemberAuthData {
        return currentMember ?
            {
                AuthContextType: currentMember.AuthContextType,
                AuthNiceName: currentMember.AuthNiceName,
                UserId: currentMember.UserId,
                GroupId: currentMember.GroupId,
                Country: currentMember.Country,
                MemberName: currentMember.MemberName,
                MemberSurname: currentMember.MemberSurname,
                Email: currentMember.Email,
                IsEmailValidated: currentMember.IsEmailValidated,
                MobilePhonePrefix: currentMember.MobilePhonePrefix,
                MobilePhone: currentMember.MobilePhone,
                MobilePhoneCountryId: currentMember.MobilePhoneCountryId,
                IsMobileValidated: currentMember.IsMobileValidated,
                IsOnlineRegistered: currentMember.IsOnlineRegistered,
                HasAccount: currentMember.HasAccount,
                TokenId: currentMember.TokenId,
                CardNumber: currentMember.CardNumber,
                TierId: currentMember.TierId,
                Birthday: currentMember.Birthday,
                Gender: currentMember.Gender,
                FavoriteLocationNo: currentMember.FavoriteLocationNo,
                HomeLocationNo: currentMember.HomeLocationNo,
                HomeVenueNo: currentMember.HomeVenueNo,
                MobilePushNotificationsAllowed: currentMember.MobilePushNotificationsAllowed,
                SMSMarketingAllowed: currentMember.SMSMarketingAllowed,
                ReceivePromoAllowed: currentMember.ReceivePromoAllowed,
                IsLocalMemberOnly: currentMember.IsLocalMemberOnly,
                MemberPoints: currentMember.MemberPoints,
                DiscountDollarBalance: currentMember.DiscountDollarBalance,
                PercentageDiscount: currentMember.PercentageDiscount,
                PriceLevelId: currentMember.PriceLevelId
            } : null;
    }

    public static mapCheckMemberUniqueCodeGETResponse(member: APIv3.MemberModel): IMemberModel {
        return member ?
            {
                MemberId: member.MemberId,
                MemberName: member.MemberName,
                MemberSurname: member.MemberSurname,
                MemberCardNumber: member.MemberCardNumber,
                MemberGroupId: member.MemberGroupId,
                MemberGroup: member.MemberGroup,
                AccountBalance: member.AccountBalance,
                CompanyName: member.CompanyName,
                StreetAddress: member.StreetAddress,
                StreetAddress2: member.StreetAddress2,
                PostCode: member.PostCode,
                Email: member.EmailAddress,
                City: member.City,
                State: member.State,
                HomePhone: member.HomePhone,
                MobilePhone: member.MobilePhone,
                MobilePhoneCountryId: member.MobilePhoneCountryID,
                ABN: member.ABN,
                BankName: member.BankName,
                BankAccountName: member.BankAccountName,
                BankAccountNumber: member.BankAccountNumber,
                ShowCompanyName: member.ShowCompanyName,
                HasAccount: member.HasAccount,
                MemberPoints: member.MemberPoints,
                AccountLimit: member.AccountLimit,
                PercentageDiscount: member.PercentageDiscount,
                IsActive: member.IsActive,
                IsFinancial: member.IsFinancial,
                TierId: member.TierId,
                Birthday: member.Birthday,
                SexId: member.SexId,
                Sex: member.Sex,
                Nationality: member.Nationality,
                Password: member.Password,
                CreatedDate: member.CreatedDate,
                HomeVenueNo: member.HomeVenueNo,
                HomeLocationNo: member.HomeLocationNo,
                ClerkId: member.ClerkId,
                EventSystemCardNumber: member.EventSystemCardNumber,
                EventOrgCode: member.EventOrgCode,
                IsOnlineRegistered: member.IsOnlineRegistered,
                LastChangedDate: member.LastChangedDate,
                PriceLevel: member.PriceLevel,
                IsLocalMemberOnly: member.IsLocalMemberOnly,
                FavoriteLocationId: member.FavoriteLocationId,
                RecievePromo: member.RecievePromo,
                VerificationCode: member.VerificationCode,
                DiscountDollarBalance: member.DiscountDollarBalance,
                InterfaceCardNumber: member.InterfaceCardNumber,
                EGMMemberId: member.EGMMemberId,
                PreferredName: member.PreferredName,
                IsSMSMarketingAllowed: member.IsSMSMarketingAllowed,
                IsEmailValidated: member.IsEmailValidated,
                IsMobileValidated: member.IsMobileValidated,
                MobilePushNotificationsAllowed: member.MobilePushNotificationsAllowed,
                AppleUserId: member.AppleUserId,
                UserId: member.MemberId,
            } : null;
    }

    public static mapMembersGETResponse(member: APIv3.MemberModel): IMemberModel {
        return member ?
            {
                MemberId: member.MemberId,
                MemberName: member.MemberName,
                MemberSurname: member.MemberSurname,
                MemberCardNumber: member.MemberCardNumber,
                MemberGroupId: member.MemberGroupId,
                MemberGroup: member.MemberGroup,
                AccountBalance: member.AccountBalance,
                CompanyName: member.CompanyName,
                StreetAddress: member.StreetAddress,
                StreetAddress2: member.StreetAddress2,
                PostCode: member.PostCode,
                Email: member.EmailAddress,
                City: member.City,
                State: member.State,
                HomePhone: member.HomePhone,
                MobilePhone: member.MobilePhone,
                MobilePhoneCountryId: member.MobilePhoneCountryID,
                ABN: member.ABN,
                BankName: member.BankName,
                BankAccountName: member.BankAccountName,
                BankAccountNumber: member.BankAccountNumber,
                ShowCompanyName: member.ShowCompanyName,
                HasAccount: member.HasAccount,
                MemberPoints: member.MemberPoints,
                AccountLimit: member.AccountLimit,
                PercentageDiscount: member.PercentageDiscount,
                IsActive: member.IsActive,
                IsFinancial: member.IsFinancial,
                TierId: member.TierId,
                Birthday: member.Birthday,
                SexId: member.SexId,
                Sex: member.Sex,
                Nationality: member.Nationality,
                Password: member.Password,
                CreatedDate: member.CreatedDate,
                HomeVenueNo: member.HomeVenueNo,
                HomeLocationNo: member.HomeLocationNo,
                ClerkId: member.ClerkId,
                EventSystemCardNumber: member.EventSystemCardNumber,
                EventOrgCode: member.EventOrgCode,
                IsOnlineRegistered: member.IsOnlineRegistered,
                LastChangedDate: member.LastChangedDate,
                PriceLevel: member.PriceLevel,
                IsLocalMemberOnly: member.IsLocalMemberOnly,
                FavoriteLocationId: member.FavoriteLocationId,
                RecievePromo: member.RecievePromo,
                VerificationCode: member.VerificationCode,
                DiscountDollarBalance: member.DiscountDollarBalance,
                InterfaceCardNumber: member.InterfaceCardNumber,
                EGMMemberId: member.EGMMemberId,
                PreferredName: member.PreferredName,
                IsSMSMarketingAllowed: member.IsSMSMarketingAllowed,
                IsEmailValidated: member.IsEmailValidated,
                IsMobileValidated: member.IsMobileValidated,
                MobilePushNotificationsAllowed: member.MobilePushNotificationsAllowed,
                AppleUserId: member.AppleUserId,
                UserId: member.MemberId,
            } : null;
    }

    public static mapMembersPUTResponse(data: APIv3.MembersUpdateMemberPersonalData.Responses.$200): boolean {
        return data;
    }

    public static mapMembersPUTRequest(data: IMemberModel): APIv3.MemberProfileUpdateRequestModel {
        return data ? {
            MemberName: data.MemberName,
            MemberSurname: data.MemberSurname,
            Email: data.Email,
            MobilePhone: data.MobilePhone,
            MobilePhoneCountryId: data.MobilePhoneCountryId,
            Birthday: data.Birthday,
            SexId: data.SexId,
            FavoriteLocationId: data.FavoriteLocationId,
            HomeLocationNo: data.HomeLocationNo,
            HomeVenueNo: data.HomeVenueNo,
            ReceivePromoAllowed: data.ReceivePromoAllowed,
            MobilePushNotificationsAllowed: data.MobilePushNotificationsAllowed,
            IsSMSMarketingAllowed: data.IsSMSMarketingAllowed,
            IsEmailValidated: data.IsEmailValidated,
            IsMobileValidated: data.IsMobileValidated,
        } : null;
    }

    public static mapUpdatePasswordPUTResponse(data: APIv3.MembersChangeMemberPassword.Responses.$200): boolean {
        return data;
    }

    public static mapUpdatePasswordPUTRequest(data: IMemberChangePasswordBusinessModel): APIv3.MembersChangeMemberPassword.Parameters.Model {
        return data ? {
            OldPassword: data.OldPassword,
            NewPassword: data.NewPassword,
        } : null;
    }

    public static mapResetForgottenPasswordPUTResponse(data: APIv3.AuthForgotPasswordReset.Responses.$200): boolean {
        return data;
    }

    public static mapResetForgottenPasswordPUTRequest(data: IMemberForgottenPasswordResetModel): APIv3.AuthForgotPasswordReset.Parameters.Model {
        return data ? {
            NewPassword: data.NewPassword,
            Token: data.Token,
        } : null;
    }

    public static mapConfirmEmailAddressPUTResponse(data: APIv3.AuthConfirmMemberEmail.Responses.$200): boolean {
        return data;
    }

    public static mapConfirmEmailAddressPUTRequest(data: string): APIv3.AuthConfirmMemberEmail.Parameters.Model {
        return data ? {
            Token: data
        } : null;
    }

    public static mapValidateMemberPasswordResetTokenPOSTResponse(data: APIv3.AuthValidateResetForgottenPasswordToken.Responses.$200): boolean {
        return data;
    }

    public static mapValidateMemberPasswordResetTokenPOSTRequest(data: string): APIv3.AuthValidateResetForgottenPasswordToken.Parameters.Model {
        return data ? {
            Token: data
        } : null;
    }

    public static mapValidateMemberConfirmEmailTokenPOSTResponse(data: APIv3.AuthValidateEmailConfirmationToken.Responses.$200): boolean {
        return data;
    }

    public static mapValidateMemberConfirmEmailTokenPOSTRequest(data: string): APIv3.AuthValidateEmailConfirmationToken.Parameters.Model {
        return data ? {
            Token: data
        } : null;
    }

    public static mapValidateMemberByPropertyPOSTResponse(data: APIv3.ValidateMemberLoginResponse, validatedProperty: string, memberId: number): boolean {
        const mappedData = {
            Id: data.Id,
            IsMobileValidated: data.IsMobileValidated,
            IsEmailValidated: data.IsEmailValidated,
            IsOnlineRegistered: data.IsOnlineRegistered,
        };

        if (memberId) {
            if (mappedData.Id === memberId) {
                return true;
            }
        }

        return !mappedData[validatedProperty];
    }

    public static mapValidateMemberByPropertyPOSTRequest(data: {
        Login: string;
        LoginType: number;
        MobilePhoneCountryId: number;
    }): APIv3.ValidateMemberLoginRequest {
        return data ? {
            Login: data.Login,
            LoginType: data.LoginType,
            MobilePhoneCountryId: data.MobilePhoneCountryId,
        } : null;
    }

    public static mapIsMobileNumberRegisteredPOSTResponse(data: APIv3.ValidateMemberLoginResponse): APIv3.ValidateMemberLoginResponse {
        return data ? {
            Id: data.Id,
            IsMobileValidated: data.IsMobileValidated,
            IsEmailValidated: data.IsEmailValidated,
            IsOnlineRegistered: data.IsOnlineRegistered
        } : null;
    }


    public static mapIsMobileNumberRegisteredPOSTRequest(data: {
        Login: string;
        LoginType: number;
        MobilePhoneCountryId: number;
    }): APIv3.ValidateMemberLoginRequest {
        return data ? {
            Login: data.Login,
            LoginType: data.LoginType,
            MobilePhoneCountryId: data.MobilePhoneCountryId,
        } : null;
    }

    public static mapResetPasswordPOSTResponse(data: APIv3.AuthResendForgotPassword.Responses.$200): boolean {
        return data;
    }

    public static mapResetPasswordPOSTRequest(data: string): APIv3.AuthResendForgotPassword.Parameters.Model {
        return data ? {
            MemberEmail: data,
            LoyaltyAppId: null
        } : null;
    }

    public static mapResendEmailConfirmationPOSTResponse(data: APIv3.AuthResendMemberEmailConfirmation.Responses.$200): boolean {
        return data;
    }

    public static mapResendEmailConfirmationPOSTRequest(data: IMemberEmailConfirmationRequestModel, LoyaltyAppId: number):
    APIv3.AuthResendMemberEmailConfirmation.Parameters.Model {
        return data ? {
            MemberEmail: data.MemberEmail,
            LoyaltyAppId: LoyaltyAppId ? LoyaltyAppId : data.LoyaltyAppId,
            MemberId: data.MemberId,
        } : null;
    }

    public static mapResendForgotPasswordConfirmationPOSTResponse(data: APIv3.AuthResendForgotPassword.Responses.$200): boolean {
        return data;
    }

    public static mapResendForgotPasswordConfirmationPOSTRequest(email: string, LoyaltyAppId: number):
    APIv3.AuthResendForgotPassword.Parameters.Model {
        return {
            MemberEmail: email,
            LoyaltyAppId: LoyaltyAppId
        };
    }

    public static mapValidateLoginPOSTResponse(validateMember: APIv3.AuthValidateMemberLogin.Responses.$200,
        phone: string,
        countryId: string,
        loginType: OLO.Enums.LOGIN_TYPE): IMemberModel {
        return validateMember ?
            {
                MemberId: validateMember.Id,
                MobilePhone: loginType === OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN ? phone : null,
                Email: loginType === OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN ? phone : null,
                MobilePhoneCountryId: loginType !== OLO.Enums.LOGIN_TYPE.MEMBER_CARD_NUMBER_BASED_LOGIN ? parseInt(countryId, 10) : null,
                IsEmailValidated: validateMember.IsEmailValidated,
                IsOnlineRegistered: validateMember.IsOnlineRegistered,
                IsMobileValidated: validateMember.IsMobileValidated
            } : null;
    }

    public static mapValidateLoginPOSTRequest(data: IValidateMemberLoginRequest):
    APIv3.AuthValidateMemberLogin.Parameters.Request {
        return {
            Login: data.Login,
            LoginType: data.LoginType,
            MobilePhoneCountryId: data.MobilePhoneCountryId,
        };
    }

    public static mapFreeProductsForMemberGETResponse(data: APIv3.MembersGetMemberFreeProducts.Responses.$200): APICommon.IMemberFreeProductModel[] {
        return data?.Items ? data?.Items.map((item: APIv3.MemberFreeProductModel) => ({
            FreeProductId: item.FreeProductId,
            ProductId: item.ProductId,
            PLU: item.PLU,
            IsBirthDayProduct: item.IsBirthDayProduct,
            MemberId: item.MemberId,
            DateIssued: item.DateIssued,
            ExpiryDate: item.ExpiryDate,
            IsRedeemed: item.IsRedeemed,
            DateRedeemed: item.DateRedeemed,
            RedeemedTerminalNo: item.RedeemedTerminalNo,
            ProductDescription: item.ProductDescription,
            POSDisplay: item.POSDisplay,
            OnlineName: item.OnlineName,
            AvailabilityTemplateId: item.AvailabilityTemplateId,
            ProductTags: item.ProductTags ?
                item.ProductTags.map((tag: APIv3.TagModel) => ({
                    Id: tag.Id,
                    Name: tag.Name,
                })) : null
        })) : null;
    }

    public static mapMemberAccountBalanceGETResponse(data: APIv3.MembersGetMemberAccountBalance.Responses.$200): IGetMemberAccountBalanceResponse {
        return data ? {
            CurrentBalance: data.CurrentBalance,
            AvailableBalance: data.AvailableBalance,
        } : null;
    }

    public static mapHistoryTransactionsGETResponse(data: APIv3.MembersGetMemberTransactions.Responses.$200): ITransactionBusinessModel[] {
        return data?.Items ? data?.Items.map((item: APIv3.TransactionBusinessModel) => ({
            TransactionId: item.TransactionId,
            ReceiptNo: item.ReceiptNo,
            TerminalName: item.TerminalName,
            TerminalNo: item.TerminalNo,
            VenueName: item.VenueName,
            Location: item.Location,
            LocationNo: item.LocationNo,
            Date: item.Date,
            Clerk: item.Clerk,
            ClerkId: item.ClerkId,
            Total: item.Total,
            SaleType: item.SaleType,
            TotalGross: item.TotalGross,
            TaxTotal: item.TaxTotal,
            TransactionMedias: item.TransactionMedias ?
                item.TransactionMedias.map((transaction: APIv3.TransactionMediaBusinessModel) => ({
                    MediaId: transaction.MediaId,
                    MediaNo: transaction.MediaNo,
                    MediaValue: transaction.MediaValue,
                    CreditCardNo: transaction.CreditCardNo,
                    CreditDateExpiryDate: transaction.CreditDateExpiryDate,
                })) : null,
            TransactionItems: item.TransactionItems ?
                item.TransactionItems.map((transaction: APIv3.TransactionItem) => ({
                    ItemID: transaction.ItemID,
                    ProductID: transaction.ProductID,
                    PLU: transaction.PLU,
                    Quantity: transaction.Quantity,
                    Total: transaction.Total,
                    TotalGross: transaction.TotalGross,
                    TotalTax: transaction.TotalTax,
                    DiscountValue: transaction.DiscountValue,
                    MenuFlowID: transaction.MenuFlowID,
                    Cost: transaction.Cost,
                    MenuFlowActivationIndex: transaction.MenuFlowActivationIndex,
                    SeatNumber: transaction.SeatNumber,
                })) : null,
            IsEditable: item.IsEditable,
            MemberId: item.MemberId,
            MemberCardNumber: item.MemberCardNumber,
            Barcode: item.Barcode,
            Points: item.Points,
            BumpTimes: item.BumpTimes ?
                item.BumpTimes.map((bump: APIv3.KMSInfo) => ({
                    TransactionID: bump.TransactionID,
                    HostTerminal: bump.HostTerminal,
                    SourceTerminal: bump.SourceTerminal,
                    ReceiptNumber: bump.ReceiptNumber,
                    ReBumped: bump.ReBumped,
                    TimeTaken: bump.TimeTaken,
                    SaleDate: bump.SaleDate,
                    DisplayNumber: bump.DisplayNumber,
                    SaleGuid: bump.SaleGuid,
                })) : null,
            ExternalClerkId: item.ExternalClerkId,
            OrderTypeID: item.OrderTypeID,
            Covers: item.Covers,
        })) : null;
    }

    public static mapLatestTransactionsGETResponse(data: APIv3.MembersGetMemberNewestTransactions.Responses.$200): ILoyaltyAppTransactionModel[] {
        return data?.Items ? data?.Items.map((item: APIv3.LoyaltyAppTransactionModel) => ({
            TransactionId: item.TransactionId,
            MemberId: item.MemberId,
            MemberName: item.MemberName,
            OnlineOrderId: item.OnlineOrderId,
            LocationNo: item.LocationNo,
            LocationFriendlyName: item.LocationFriendlyName,
            LocationDescription: item.LocationDescription,
            LocationNotes: item.LocationNotes,
            LocationStreetAddress: item.LocationStreetAddress,
            LocationSuburb: item.LocationSuburb,
            LocationState: item.LocationState,
            LocationPostCode: item.LocationPostCode,
            Total: item.Total,
            TotalGross: item.TotalGross,
            TaxTotal: item.TaxTotal,
            Date: item.Date,
            Barcode: item.Barcode,
            OrderNumber: item.OrderNumber,
            ReceiptNumber: item.ReceiptNumber,
            TransactionProducts: item.TransactionProducts ?
                item.TransactionProducts.map((transaction: APIv3.LoyaltyAppTransactionProduct) => ({
                    ProductID: transaction.ProductID,
                    PLU: transaction.PLU,
                    POSDisplay: transaction.POSDisplay,
                    ProductDescription: transaction.ProductDescription,
                    Quantity: transaction.Quantity,
                    Total: transaction.Total,
                    TotalGross: transaction.TotalGross,
                    TotalTax: transaction.TotalTax,
                    DiscountValue: transaction.DiscountValue,
                })) : null,
            TransactionMenuFlows: item.TransactionMenuFlows ?
                item.TransactionMenuFlows.map((transaction: APIv3.LoyaltyAppTransactionMenuFlow) => ({
                    ActivationId: transaction.ActivationId,
                    MenuFlowId: transaction.MenuFlowId,
                    CustomerFriendlyName: transaction.CustomerFriendlyName,
                    MenuFlowDescription: transaction.MenuFlowDescription,
                    Notes: transaction.Notes,
                    Quantity: transaction.Quantity,
                    Total: transaction.Total,
                    TotalGross: transaction.TotalGross,
                    TotalTax: transaction.TotalTax,
                    DiscountValue: transaction.DiscountValue,
                    MenuFlowProducts: transaction.MenuFlowProducts ?
                        transaction.MenuFlowProducts.map((menuFlow: APIv3.LoyaltyAppTransactionProduct) => ({
                            ProductID: menuFlow.ProductID,
                            PLU: menuFlow.PLU,
                            POSDisplay: menuFlow.POSDisplay,
                            ProductDescription: menuFlow.ProductDescription,
                            Quantity: menuFlow.Quantity,
                            Total: menuFlow.Total,
                            TotalGross: menuFlow.TotalGross,
                            TotalTax: menuFlow.TotalTax,
                            DiscountValue: menuFlow.DiscountValue,
                        })) : null,
                })) : null,
        })) : null;
    }
}
