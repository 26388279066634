import { createAction, props } from '@ngrx/store';
import { IPaymentCreditCardData, IGetTransactionResponse } from '../interface';

export const PaymentReset = createAction('[Payment] Reset');

export const PaymentInitWithRedirect = createAction('[Payment] Init with redirect');

export const PaymentInit = createAction(
    '[Payment] Init - trigger payment process with prevalidated and generated card token',
);

export const PaymentInitWithPaymentMethod = createAction(
    '[Payment] Init with payment method - trigger payment process with prevalidated and generated card token',
    (paymentMethod: OLO.Ordering.IPaymentMethod = null) => ({ paymentMethod })
);

export const PaymentClearErrors = createAction(
    '[Payment] Clear Errors',
);

/* STEPS */
export const PaymentStepValidateGuestData = createAction(
    '[Payment] Step - validate guest data',
    (creditCard: IPaymentCreditCardData = null) => ({ creditCard })
);

export const PaymentStepValidateGuestCardToken = createAction('[Payment] Step - validate guest get card token');

export const PaymentStepValidateMemberCardToken = createAction('[Payment] Step - validate member card token');

export const PaymentStepCreateOrder = createAction('[Payment] Step - create order');

export const PaymentStepRedirect = createAction('[Payment] Step - redirect');

export const PaymentForceStep = createAction('[Payment] Set custom payment step i.e. for vendor payments to provide out of flow flag', props<{step: string; }>());

export const PaymentStepPay = createAction('[Payment] Step - pay');

export const PaymentStepPaymentStatusCheck = createAction(
    '[Payment] Step - payment status check',
    props<{ TransactionId: string; OrderId: number; }>(),
);

export const PaymentStepComplete = createAction(
    '[Payment] Step - payment complete',
    props<{ OrderId: number; payload: IGetTransactionResponse; }>(),
);

export const PaymentStepSkipForZeroPricedOrder = createAction(
    '[Payment] Step - payment skipped for zero priced order'
);

export const PaymentStepFailed = createAction(
    '[Payment] Step = payment failed',
    (Message: string, Id: number = new Date().getTime() + Math.floor(Math.random() * 100000)) => ({ Message, Id })
);
