import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable, of, combineLatest, timer } from 'rxjs';
import { map, delay, withLatestFrom, take, switchMap, filter } from 'rxjs/operators';
import { IActivatedOrderVoucherDetails, IOrder } from '@shared/state';
import IOnlineMenuProductResponseModel = APICommon.IOnlineMenuProductResponseModel;

@Injectable({
    providedIn: 'root'
})
export class OnlineOrdersController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
        private _modalsService: Services.ModalsService,
        private _onlineOrdersService: Services.OnlineOrdersService,
        private _routeService: Services.RouteService,
        private _paymentService: Services.PaymentsService,
    ) { }

    private _setDefaultDisclaimersValues(orderType: IOrder, config: IConfig): APICommon.OrderTypeExtended {
        if (!orderType || !config) return orderType;

        return {
            ...orderType,
            Disclaimers: orderType.Disclaimers?.map(disclaimer => ({
                ...disclaimer,
                _Value: null,
            })) || null
        };
    }

    public isAllowedToPlaceNextOrder$() {
        return this._store
            .pipe(
                select(selectors.getOrderSummary),
                filter(order => order !== null),
                switchMap(order => this._store
                    .pipe(
                        select(selectors.getPaymentState),
                        filter(payment => payment.paymentStepStatus === null),
                        map(() => order)
                    )),
            );
    }

    public payWithGoogle(): void {
        this.isAllowedToPlaceNextOrder$()
            .pipe(
                take(1)
            )
            .subscribe(order => {
                this._store.dispatch(actions.PaymentForceStep({ step: 'paying' }));

                this._paymentService.googlePayPaymentProviderService.pay(`${order.Total}`)
                    .then(PaymentData => {
                        if(PaymentData) {
                            return this.placeOrderWithPaymentProvider({
                                paymentMethod: {
                                    amount: order.Total,
                                    vendorService: OLO.Enums.PAYMENT_VENDOR_SERVICE.GOOGLE_PAY,
                                    googlePaymentData: {
                                        PaymentData
                                    }
                                }
                            });
                        }

                        throw new Error(`Unable to process google payment details ${PaymentData}`);
                    })
                    .catch(ex => {
                        console.error(ex);
                        this._store.dispatch(State.PaymentReset());
                    });
            });
    }

    public async setupGooglePay(
        config: Partial<google.payments.api.PaymentOptions>,
        payButtonContainer: HTMLElement,
        onPayButtonClickCallback: () => any = this.payWithGoogle.bind(this)
    ): Promise<google.payments.api.PaymentsClient> {
        return this._paymentService.googlePayPaymentProviderService.setup(
            config,
            payButtonContainer,
            onPayButtonClickCallback
        );
    }

    public placeOrderWithApplePay(): void {
        this.isAllowedToPlaceNextOrder$()
            .pipe(
                take(1)
            )
            .subscribe(order => {
                this._store.dispatch(actions.PaymentForceStep({ step: 'paying' }));

                this._paymentService.applePayPaymentProviderService.pay(order.Total.toFixed(2))
                    .then(PaymentData => {
                        if(PaymentData) {
                            return this.placeOrderWithPaymentProvider({
                                paymentMethod: {
                                    amount: order.Total,
                                    vendorService: OLO.Enums.PAYMENT_VENDOR_SERVICE.APPLE_PAY,
                                    applePaymentData: {
                                        PaymentData
                                    }
                                }
                            });
                        }
                        throw new Error(`Unable to process google payment details ${PaymentData}`);
                    })
                    .catch(ex => {
                        console.error(ex);
                        this._store.dispatch(State.PaymentReset());
                    });
            });
    }

    public placeOrderWithRedirectType(): void {
        this.isAllowedToPlaceNextOrder$()
            .pipe(
                take(1)
            )
            .subscribe(() => {
                this._onlineOrdersService.placeOrderWithRedirectPaymentProvider();
            });
    }

    public placeOrder(): void {
        this._store
            .pipe(
                select(selectors.getCardState),
                withLatestFrom(
                    this._store
                        .pipe(
                            select(selectors.getActiveCardDetails)
                        )
                ),
                take(1)
            ).subscribe(([_, activeCard]) => {
                if(this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN && activeCard.Id && !activeCard.AdyenPaymentData?.encryptedSecurityCode) {
                    return this._modalsService.show({
                        type: 'adyen-cvv'
                    });
                }

                this.placeOrderWithPaymentProvider();
            });

    }

    public placeOrderWithAdyenCVVCode(encryptedSecurityCode: string): void {
        this._store
            .pipe(
                select(selectors.getActiveCardDetails),
                take(1)
            ).subscribe(activeCard => {
                if (!activeCard.Id || !encryptedSecurityCode) {
                    throw new Error(`Invalid card id ${activeCard?.Id} or Adyen CVV code ${encryptedSecurityCode}`);
                }

                this._store.dispatch(actions.CreditCardsAdyenAppendCVVNumber({ cardId: activeCard.Id, encryptedSecurityCode }));

                this.placeOrderWithPaymentProvider();
            });
    }

    public placeOrderWithPaymentProvider(paymentConfig?: OLO.Ordering.IPaymentConfig): void {
        this._onlineOrdersService.placeOrder(paymentConfig)
            .then(summary => this._routeService.saveConfirmationUrlAndNavigateToOrderConfirmation(summary.orderId, summary.locationNo))
            .catch(ex => {
                console.error('PlaceOrderError', ex);

                return false;
            });
    }

    public sendEmailReceipt(orderId: number): void {
        this._store.dispatch(actions.OnlineOrderSendEmailReceiptRequest({ orderId }));
    }

    public isSendingEmailReceipt$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isSendingEmailReceipt)
            );
    }

    public hasSendingEmailReceiptSucceeded$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.hasSendingEmailReceiptSucceeded)
            );
    }

    public hasSendingEmailReceiptFailed$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.hasSendingEmailReceiptFailed)
            );
    }

    public resetSendingEmailReceiptState(): void {
        this._store
            .pipe(
                select(selectors.isSendingEmailReceipt),
                filter(isSending => isSending === false),
                take(1)
            ).subscribe(() => {
                this._store.dispatch(actions.OnlineOrderSendEmailReceiptReset());
            });
    }

    public isOrderTypeValid$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isOrderTypeValid(this._config)),
            );
    }

    public showPristineDisclaimerFields$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.orderTypeFieldsVisible(this._config)),
            );
    }

    public getMappedErrors$(): Observable<OLO.Components.IMappedMessage> {
        return this._store
            .pipe(
                select(selectors.getOrderErrorsMapped)
            );
    }

    public getMinimumSpendError$(): Observable<OLO.Components.IMappedMessage[]> {
        return this._store
            .pipe(
                select(selectors.errorMinimumSpendCriteria)
            );
    }
    public async fillOutOrderType(formValues: { [key: string]: string | boolean; }, orderType: IOrder): Promise<boolean> {
        const details: APICommon.OrderTypeDetailDefinitionExtended[] = [];
        const disclaimers: APICommon.OrderTypeDisclaimerDefinitionExtended[] = [];

        Object.keys(formValues).forEach(formKey => {
            const foundDetail = orderType.Details.find(obj => Utils.Forms.labelToName(obj.CustomerFriendlyName) === formKey);
            const foundDisclaimer = this._config.checkoutDisclaimers?.enabled === true ?
                orderType.Disclaimers?.find(obj => Utils.Forms.labelToName(obj.CustomerFriendlyName) === formKey)
                : undefined;

            if (foundDetail) {
                details.push({
                    ...foundDetail,
                    _Value: formValues[formKey] as string
                });
            }

            if (foundDisclaimer) {
                disclaimers.push({
                    ...foundDisclaimer,
                    _Value: formValues[formKey] as boolean || false
                });
            }
        });

        if (!details.length && !disclaimers.length) {
            return false;
        }

        this._store.dispatch(actions.OnlineOrderTypeUpdateValues({ details, disclaimers }));

        return true;
    }

    public showOrderTypesInfoForCurrentCart(): void {
        this._store
            .pipe(
                select(selectors.getCartLocationNo),
                take(1)
            ).subscribe(locationNo => {
                this._modalsService.show({
                    type: 'order-types',
                    locationNo,
                });
            });
    }

    public getOrderTypesForCartsLocation$(): Observable<IOrder[]> {
        return this._store
            .pipe(
                select(selectors.getOrderTypesForCartsLocation)
            );
    }

    public getOrderTypesForCartsLocationMapped$(): Observable<OLO.Components.OptionTabs.IOption[]> {
        return this._store
            .pipe(
                select(selectors.getFilteredOrderTypesForCartsLocationByGroup(this._config))
            );
    }

    public showOrderTypeSection$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.showOrderTypeSection(this._config))
            );

    }

    public showPaymentSection$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.canShowPaymentSection)
            );
    }

    public selectOrderType(orderType: IOrder, forceRecalculate: boolean = false): void {
        this._store.dispatch(actions.OnlineOrderTypeSelect({ orderType: this._setDefaultDisclaimersValues(orderType, this._config) }));
        if (forceRecalculate) {

            this._store.dispatch(actions.OnlineOrderRecalculateRequest());
        }
    }

    public resetOrderTypeValues(): void {
        this._store.dispatch(actions.OnlineOrderResetOrderTypeValues());
    }


    public selectDefaultOrderType(): void {
        this._store.pipe(
            select(selectors.hasDownloadedOrderTypesForCurrentCartLocation),
            filter(orderTypesDownloaded => orderTypesDownloaded === true),
            switchMap(() => this.getOrderTypesForCartsLocation$()
                .pipe(
                    withLatestFrom(
                        this._store
                            .pipe(
                                select(selectors.getOnlineOrderState)
                            ),
                        this._store
                            .pipe(
                                select(selectors.getCollectionType)
                            ),
                        this._store
                            .pipe(
                                select(selectors.getSelectedCollectionTypeGroupId(this._config))
                            ),
                        this._store
                            .pipe(
                                select(selectors.getOrderTypesForCartsLocation)
                            ),
                        this._store
                            .pipe(
                                select(selectors.getOrderingTimeInfoByCartLocation)
                            )
                    )
                )),

            take(1),
            delay(0)
        )
            .subscribe(([orderTypes, state, collectionType, collectionTypeGroupId, locationOrderTypes, orderingTimeInfo]) => {
                const dispatchWithRecalc = (orderType: IOrder): void => {
                    this._store.dispatch(actions.OnlineOrderTypeSelect({ orderType }));
                    this._store.dispatch(actions.OnlineOrderRecalculateRequest());
                };

                if (state.orderType !== null) return;

                if (collectionType?.orderTypeId) {
                    const currentOrderTypes: IOrder[] = orderTypes || locationOrderTypes;
                    const foundOrderType = currentOrderTypes?.find(obj => obj.Id === collectionType.orderTypeId);
                    if (foundOrderType) {
                        const withDefaults = this._setDefaultDisclaimersValues(foundOrderType, this._config);

                        dispatchWithRecalc(withDefaults);

                        if (collectionTypeGroupId === OLO.Enums.COLLECTION_TYPE.DINE_IN) {
                            if (collectionType.tableNo) {
                                const detailsWithValues = withDefaults.Details.map((obj, index) => {
                                    if (index) return obj;

                                    return {
                                        ...obj,
                                        _Value: collectionType.tableNo
                                    };
                                });

                                this._store.dispatch(actions.OnlineOrderTypeUpdateValues({ details: detailsWithValues, disclaimers: withDefaults.Disclaimers || [] }));

                                return this._store.dispatch(actions.OnlineOrderRecalculateRequest());
                            }

                            return;
                        }
                    }
                }

                const isLocationOpen = orderingTimeInfo && new Utils.LocatioOpenStatus(orderingTimeInfo[0])
                    .isOpenForProvidedDate(Utils.Dates.getLocalISOFormatDate(new Date())) || false;

                let firstAvailableOrderType: IOrder;

                orderTypes.forEach(orderType => {
                    if (firstAvailableOrderType) return;

                    const orderTypeCheck = new Utils.CollectionTypeGroupDetector(orderType.Id, this._config);
                    if (orderTypeCheck.isDineIn() && !isLocationOpen) {
                        return;
                    }

                    firstAvailableOrderType = orderType;
                });

                if (collectionTypeGroupId) {
                    const orderType: IOrder = orderTypes.find(obj => new Utils.CollectionTypeGroupDetector(obj.Id, this._config)
                        .getCollectionType() === collectionTypeGroupId
                    );
                    if (orderType) {
                        return dispatchWithRecalc(this._setDefaultDisclaimersValues(orderType, this._config));
                    }
                }

                dispatchWithRecalc(this._setDefaultDisclaimersValues(firstAvailableOrderType || null, this._config));
            });
    }

    public getSelectedOrderType$(): Observable<APICommon.OrderTypeExtended> {
        return this._store
            .pipe(
                select(selectors.getSelectedOrderType)
            );
    }

    public showSelectedOrderTypeContent$(): Observable<boolean> {
        /* (selectedOrderType$ | async)?.Details?.length > 0 */
        return this.getSelectedOrderType$()
            .pipe(
                filter(orderType => orderType !== null),
                map(orderType => {
                    const orderTypeHasDisclaimersConfigured: boolean = this._config.checkoutDisclaimers.enabled === true && orderType.Disclaimers?.length > 0;
                    const orderTypeHasDetailsConfigured: boolean = orderType.Details?.length > 0;

                    return orderTypeHasDisclaimersConfigured || orderTypeHasDetailsConfigured;
                })
            );
    }

    public getOrderTypesForLocation$(locationNo: number): Observable<State.IOrderType> {
        return this._store
            .pipe(
                select(selectors.getOrderTypesForLocation(locationNo))
            );
    }

    public orderSummary$(): Observable<OLO.Ordering.IOrderSummary> {
        return this._store
            .pipe(
                select(selectors.getOrderSummary)
            );
    }

    public isRecalculating$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isOnlineOrderRecalculating)
            );
    }

    public canPlaceOrderWithCard$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getCart),
                switchMap(cart => this._store
                    .pipe(
                        select(selectors.isCardTypePaymentDisabled(cart.locationNo, this._config)),
                        map(isDisabled => !isDisabled)
                    )
                )
            );
    }

    public canPlaceOrderWithVendorService$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getCart),
                switchMap(cart => this._store
                    .pipe(
                        select(selectors.isVendorServicePaymentDisabled(cart.locationNo, this._config)),
                        map(isDisabled => !isDisabled)
                    )
                )
            );
    }

    public totalGrossValue$(): Observable<number> {
        return this.orderSummary$()
            .pipe(
                map(summary => summary ? summary.Total : 0)
            );
    }

    public isZeroPricedOrder$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isZeroPricedOrder)
            );
    }

    public getReorder$(orderId: number, locationNo: number = null, pickupTime: OLO.Ordering.IPickupTime = null): Observable<State.IReorder> {
        return combineLatest([
            this._store.pipe(select(selectors.getCurrentPickupTime)),
            this._store.pipe(select(selectors.getHistoryOrder(orderId)))
        ]).pipe(
            switchMap(([statePickupTime, order]) => {
                if (!statePickupTime && !pickupTime || !order || !order.data) return of(null);

                return this._store
                    .pipe(
                        select(selectors.getReorder(orderId, locationNo || order.data.PickupLocation, pickupTime || statePickupTime))
                    );
            })
        );
    }

    public reorderTotalSelectedItems$(orderId: number, locationNo: number = null, pickupTime: OLO.Ordering.IPickupTime = null): Observable<number> {
        return this.getReorder$(orderId, locationNo, pickupTime)
            .pipe(
                switchMap(reorder => {
                    if (!reorder) return of(0);

                    return this._store
                        .pipe(
                            select(selectors.reorderSelectedItemsTotalQuantity(reorder.OrderId, reorder.LocationNo, reorder.PickupTime))
                        );
                })
            );
    }

    public reorderTotalValue$(orderId: number, locationNo: number = null, pickupTime: OLO.Ordering.IPickupTime = null): Observable<number> {
        return this.getReorder$(orderId, locationNo, pickupTime)
            .pipe(
                switchMap(reorder => {
                    if (!reorder) return of(0);

                    return this._store
                        .pipe(
                            select(selectors.reorderSelectedItemsTotalValue(reorder.OrderId, reorder.LocationNo, reorder.PickupTime))
                        );
                })
            );
    }

    public reorderIsValid$(orderId: number, locationNo: number = null, pickupTime: OLO.Ordering.IPickupTime = null): Observable<boolean> {
        return this.getReorder$(orderId, locationNo, pickupTime)
            .pipe(
                switchMap(reorder => {
                    if (!reorder || reorder.isDownloading === true || reorder.hasSucceeded !== true || reorder.data === null) return of(false);

                    return this._store
                        .pipe(
                            select(selectors.canAddToCartReorderItems(reorder.OrderId, reorder.LocationNo, reorder.PickupTime))
                        );
                })
            );
    }

    public reorderItemsMappedFromOrder$(orderId: number, locationNo: number = null, pickupTime: OLO.Ordering.IPickupTime = null):
    Observable<OLO.Ordering.IOnlineOrderMappedProducts> {
        return combineLatest([
            this.getReorder$(orderId, locationNo, pickupTime),
            this._store.pipe(select(selectors.getHistoryOrder(orderId))),
            this._store.pipe(select(selectors.getOnlineMenuData))
        ]).pipe(
            map(([reorder, order, onlineMenu]) => {
                if (!reorder || !reorder.data || !order || !order.data || !onlineMenu) return null;

                const onlineProducts: IOnlineMenuProductResponseModel[] = onlineMenu.Pages.reduce((acc, page) => [...acc, ...page.Products], []);

                return Utils.OnlineOrders.mapOnlineOrderProducts(order.data, true, onlineProducts);
            })
        );
    }

    public reorderItems$(orderId: number, locationNo: number = null, pickupTime: OLO.Ordering.IPickupTime = null):
    Observable<Array<State.ICartMenuFlowExtended | State.ICartSimpleItemExtended>> {
        return combineLatest([
            this.reorderItemsMappedFromOrder$(orderId, locationNo, pickupTime),
            this.getReorder$(orderId, locationNo, pickupTime)
        ]).pipe(
            map(([orderItems, reorder]) => {
                if (!reorder || !reorder.data || !reorder.data.cart || !orderItems) return null;
                if (orderItems.itemsMenuFlow.length === 0 && orderItems.itemsSimple.length === 0) {
                    console.error('Corrupted data in online order', orderItems);

                    return null;
                }

                return [
                    ...orderItems.itemsMenuFlow.reduce((acc, orderItem) => {
                        const found = reorder.data.cart.itemsMenuFlow.find(obj => obj._Id === orderItem.Id);
                        if (found) {
                            acc.push(found);
                        }

                        return acc;
                    }, []),

                    ...orderItems.itemsSimple.reduce((acc, orderItem) => {

                        const found = reorder.data.cart.itemsSimple.find(obj => obj._Id === orderItem.Id);
                        if (found) {
                            acc.push(
                                {
                                    ...found,
                                    UnitPrice: orderItem.UnitPrice
                                });
                        }

                        return acc;
                    }, []),
                ];
            })
        );
    }

    public addVoucherToOrder(voucherCode: string, id: number): void {
        this._store.dispatch(actions.OnlineOrderAddVoucherRequest({ code: voucherCode, id }));
    }

    public hasActiveVouchers$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.hasActiveVouchers)
            );
    }

    public isVoucherError$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isVoucherError)
            );
    }

    public isVoucherLoading$(): Observable<string> {
        return combineLatest([
            this._store.pipe(select(selectors.isVoucherLoading)),
            this._store.pipe(select(selectors.isVoucherFailed))
        ]).pipe(
            switchMap(([isVoucherLoading, isVoucherFailed]) => {
                if (isVoucherLoading) {
                    return of('loading');
                }
                if (!isVoucherLoading) {
                    return !isVoucherFailed ? of(null) : timer(0, 3000).pipe(
                        take(2),
                        map(count => count === 0 ? 'error' : null)
                    );
                }
            })
        );
    }

    public voucherErrorMessage$(): Observable<string[]> {
        return this._store
            .pipe(
                select(selectors.voucherErrorMessage)
            );
    }

    public getActiveVoucher$(): Observable<IActivatedOrderVoucherDetails> {
        return this._store
            .pipe(
                select(selectors.getActiveVoucher)
            );
    }
}
