import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as uuid from 'uuid';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';
import * as Models from '@shared/core/models';

import * as StateModels from '../interface';

import { Observable, of, throwError, from } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom, delay, take, tap, filter, combineLatest, auditTime } from 'rxjs/operators';
import { IPaymentExpressCardIdResponse } from '@shared/state/interface';

@Injectable()
export class CreditCardEffects {
    @Effect() public requestCardToken$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.GetCreditCardToken,
                actions.GetCreditCardTokenWithRedirect
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCartLocationNo)
                    ),
            ),
            switchMap(([action, locationNo]) => {
                if (this._config.demoMode === true) return of(actions.__DEMO__getCardToken(action));

                if(
                    this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN ||
                    this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.STRIPE
                ) {
                    return [
                        actions.CreditCardsSuccessRequestToken({
                            cardNumber: action.cardNumber,
                            saveCard: action.saveCard,
                            expiryDate: action.expiryDate || null,
                            isDefaultPaymentMethod: action.isDefaultPaymentMethod,
                            adyenPaymentData: action.adyenPaymentData,
                            stripePaymentData: action.stripePaymentData,
                            token: action.stripePaymentData?.id || null
                        })
                    ];
                }

                const cardDetails: OLO.CreditCards.ICreditCardDetails = {
                    cardNumber: action.cardNumber,
                    cvv: action.cvv,
                    expiryDate: Utils.CreditCards.dateToApiFormat(action.expiryDate),
                };

                return this._paymentsService.requestCardTokenForDefaultPaymentProvider(cardDetails, locationNo)
                    .pipe(
                        switchMap(({ token, directPostUrl, returnUrlAfterRedirect }) => {
                            const isDefaultPaymentMethod = typeof action.isDefaultPaymentMethod !== 'boolean' ? true : action.isDefaultPaymentMethod;
                            const saveCard = typeof action.saveCard === 'boolean' ? action.saveCard : false;
                            const cardType = Utils.CreditCards.detectCardType(action.cardNumber);

                            const successData: State.ICreditCardTokenResponse = {
                                token,
                                cardNumber: action.cardNumber,
                                expiryDate: cardDetails.expiryDate,
                                cardType,
                                saveCard: action.saveCard,
                                isDefaultPaymentMethod,
                                directPostUrl: directPostUrl || null,
                                returnUrlAfterRedirect: returnUrlAfterRedirect || null,
                            };
                            if (action.type === actions.GetCreditCardTokenWithRedirect.type) {
                                const card = new Models.CreditCardBuilder()
                                    .setPaymentProvider(this._config.payments.baseProvider)
                                    .setType(cardType)
                                    .setNumber(action.cardNumber)
                                    .setExpiryDate(action.expiryDate)
                                    .setToken(null)
                                    .setIsDefault(isDefaultPaymentMethod)
                                    .setSaveCard(saveCard)
                                    .setValidationStatus('validating')
                                    .build()
                                    .toJson();


                                return [
                                    actions.AddCardToState({ card }),
                                    actions.CreditCardsSuccessRequestTokenWithRedirect(successData)
                                ];
                            }

                            return of(actions.CreditCardsSuccessRequestToken(successData));
                        }),
                        catchError(ex => {
                            console.warn('cc errror', ex);

                            return of(actions.CreditCardsErrorRequestToken({ ex }));
                        })
                    );
            })
        );

    @Effect() public validateCardOnAfterRedirect$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsValidateRequest),
            switchMap(action => this._store
                .pipe(
                    select(selectors.getLoyaltyAppSettings),
                    filter(appSettings => appSettings.data !== null),
                    take(1),
                    switchMap(appSettings => this._store
                        .pipe(
                            select(selectors.getCardState),
                            take(1),
                            withLatestFrom(
                                this._store
                                    .pipe(
                                        select(
                                            selectors.getCartLocationNo
                                        )
                                    ),
                            ),
                            switchMap(([state, cartLocationNo]) => {
                                if (this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA) {
                                    return [
                                        actions.CreditCardsValidateSuccessRequest({ responseParams: {
                                            token: null,
                                            ...action.responseParams
                                        }, card: null })
                                    ];
                                }

                                return this._paymentsService.paymentExpressPaymentProviderService.getCardDetails({
                                    sessionToken: state.sessionToken,
                                    appId: appSettings.data.Id,
                                    locationNo: cartLocationNo
                                })
                                    .pipe(
                                        map((response: IPaymentExpressCardIdResponse) => actions
                                            .CreditCardsValidateSuccessRequest({ responseParams: {
                                                token: null,
                                                ...action.responseParams
                                            }, card: response })),
                                        catchError(ex => {
                                            console.error('Unable to get payment express card details', ex);

                                            return of(actions.CreditCardsValidateErrorRequest({ responseParams: {
                                                token: null,
                                                ...action.responseParams
                                            } }));
                                        })
                                    );

                            })
                        )),
                    catchError(ex => {
                        console.error('Unable to get payment express card details', ex);

                        return of(actions.CreditCardsValidateErrorRequest({ responseParams: {
                            token: null,
                            ...action.responseParams
                        } }));
                    })
                ))
        );

    @Effect() public checkCardsToSaveCardAfterSuccessfulRedirectReturn$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CreditCardsValidateSuccessRequest
            ),
            switchMap(action => this._store
                .pipe(
                    select(selectors.getCards),
                    take(1),
                    switchMap(cards => {
                        const payloadToken: string = action.responseParams.token || action.card.CardId;
                        const card: OLO.Members.IMemberCreditCardDetails = cards
                            .find(obj => obj.Token === payloadToken && obj.SaveAwait === true && obj.Id === null);

                        if (!card) return [];

                        return of(actions.CreditCardsAddAfterRedirectRequest({ card }));
                    })
                ))
        );

    @Effect() public saveCardAfterSuccessfulRedirectReturnCheck$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CreditCardsAddAfterRedirectRequest
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCardState)
                    ),
                this._store
                    .pipe(
                        select(selectors.getCart)
                    )
            ),
            switchMap(([action, state, cart]) => {
                const cardBuilder = new Models.CreditCardBuilder()
                    .setPaymentProvider(this._config.payments.baseProvider)
                    .setType(action.card.CardType)
                    .setNumber(action.card.NiceName || action.card.DisplayName)
                    .setExpiryDate(action.card.ExpirationDate)
                    .setToken(action.card.Token)
                    .setIsDefault(!!action.card.IsDefault)
                    .setLocationNo(cart.locationNo);

                if (this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA) {
                    cardBuilder.setFatZebraToken(state.fatZebra.r, state.fatZebra.v);
                }

                const card = cardBuilder.build().toJson();

                if (this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA && !card.LocationNo) {
                    card.LocationNo = cart.locationNo;
                }

                return this._creditCardsService.addMemberCard(card)
                    .pipe(
                        switchMap(response => [
                            actions.SelectActiveCreditCardId({ cardId: response.Id }),
                            actions.CreditCardsAddAfterRedirectSuccessRequest({ card: action.card, newCard: response }),
                        ]),
                        catchError(ex => of(actions.CreditCardsAddAfterRedirectErrorRequest({ card: action.card, ex })))
                    );
            })

        );

    @Effect() public onGetTokenSuccessSaveOrSetupCardStateForPayment$: Observable<any> = this._actions$
        .pipe(
            ofType(
                actions.CreditCardsSuccessRequestToken
            ),
            switchMap(action => {
                let token = action.token;

                let card: OLO.Members.IMemberCreditCardDetails;
                if(
                    this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN ||
                    this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.STRIPE
                ) {
                    token = token || uuid.v4();
                    card = new Models.CreditCardBuilder()
                        .setPaymentProvider(this._config.payments.baseProvider)
                        .setToken(token)
                        .setNumber(action.cardNumber)
                        .setValidationStatus('success')
                        .setSaveCard(action.saveCard)
                        .setExpiryDate(action.expiryDate)
                        .setAdyenPaymentData(action.adyenPaymentData)
                        .setStripePaymentData(action.stripePaymentData)
                        .setType(Utils.CreditCards.mapBrandToEnum(action.stripePaymentData?.card?.brand || ''))
                        .build()
                        .toJson();
                } else {
                    card = new Models.CreditCardBuilder()
                        .setPaymentProvider(this._config.payments.baseProvider)
                        .setNumber(action.cardNumber)
                        .setType(action.cardType)
                        .setExpiryDate(action.expiryDate)
                        .setToken(action.token)
                        .setIsDefault(action.isDefaultPaymentMethod)
                        .setValidationStatus(action.saveCard ? 'validating' : 'success')
                        .build()
                        .toJson();
                }

                let saveCard = action.saveCard &&
                    this._config.payments.baseProvider !== OLO.Enums.PAYMENT_PROVIDER.ADYEN &&
                    this._config.payments.baseProvider !== OLO.Enums.PAYMENT_PROVIDER.STRIPE;
                if (saveCard) {
                    return of(actions.CreditCardsAddRequest({ card }));
                } else {
                    return [
                        actions.AddCardToState({ card }),
                        actions.SelectActiveCreditCardToken({ token })
                    ];
                }

            })
        );

    @Effect() public onCreditCardAddRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsAddRequest),
            switchMap(action => this._creditCardsService.addMemberCard(action.card)
                .pipe(
                    map(response =>
                        response ?
                            actions.CreditCardsAddSuccessRequest({ newCard: response }) :
                            actions.CreditCardsAddErrorRequest({})),
                    catchError(ex => of(actions.CreditCardsAddErrorRequest({ ex })))
                ))
        );

    @Effect() public onCreditCardAddRequestSuccess$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsAddSuccessRequest),
            switchMap(action => [
                actions.CreditCardsRequest(),
                actions.SelectActiveCreditCardId({ cardId: action.newCard.Id }),
                actions.CreditCardShowForm({ isAdding: false }),
            ])
        );

    @Effect() public onCreditCardsRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsRequest),
            switchMap(action => this._creditCardsService.getCardItems()
                .pipe(
                    map((freshCardsList) =>
                        freshCardsList ?
                            actions.CreditCardsSuccessRequest({ payload: freshCardsList.Items }) :
                            actions.CreditCardsErrorRequest({})),
                    catchError(ex => of(actions.CreditCardsErrorRequest({ ex })))
                ))
        );

    @Effect() public selectDefaultPaymentMethod$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CreditCardsSelectDefaultPaymentMethod,
                actions.CreditCardsSuccessRequest,
                actions.MemberAccountBalanceSuccessRequest,
            ),
            withLatestFrom(
                this._store.pipe(select(selectors.memberHasAvailableBalanceToPayForCartOrder)),
                this._store.pipe(select(selectors.getCardState)),
                this._store.pipe(select(selectors.isMemberAuthorizedJWT)),
            ),
            switchMap(([_, hasAccountAvailable, state, isAuthorized]) => {
                if(!isAuthorized) {
                    if(state.data.length) {
                        const [lastCreditCard] = state.data.slice(-1);

                        return [
                            actions.CreditCardShowForm({ isAdding: false }),
                            actions.SelectActiveCreditCardToken({ token: state.activeCardToken || lastCreditCard.Token })
                        ];
                    }
                    if(this._paymentsService.isSpecialPaymentMethodAvailable) {
                        return [
                            actions.CreditCardShowForm({ isAdding: false }),
                            actions.SelectActiveCreditCardId({ cardId: this._paymentsService.defaultVendorPaymentProvider })
                        ];
                    }

                    return [
                        actions.CreditCardShowForm({ isAdding: true }),
                    ];
                }

                if (state.activeCardId || state.activeCardToken) return [
                    actions.CreditCardShowForm({ isAdding: false }),
                ];

                const hasUnsavedCreditCard = state.data?.find(obj => obj.Id === null
                    && obj.Token === null
                    && (obj.SaveAwait === true
                        || obj.ValidationStatus === 'validating'
                        || obj.ValidationStatus === 'error'
                    ));
                if(hasUnsavedCreditCard) {
                    return [
                        actions.CreditCardShowForm({ isAdding: false }),
                    ];
                }

                const memberWithAccountCharge = this._config.accountCharge?.enabled === true && hasAccountAvailable != null;
                if(memberWithAccountCharge) {
                    return [
                        actions.CreditCardShowForm({ isAdding: false }),
                        actions.SelectActiveCreditCardId({ cardId: OLO.Enums.PAYMENT_VENDOR_SERVICE.ACCOUNT_CHARGE }),
                    ];
                }

                const defaultCard = state.data?.find(obj => obj.IsDefault === true && obj.Id !== null);
                if (defaultCard) {
                    return [
                        actions.CreditCardShowForm({ isAdding: false }),
                        actions.SelectActiveCreditCardId({ cardId: defaultCard.Id })
                    ];
                }

                if (state.data.length > 0) {
                    const firstAvailableCard = state.data?.find(obj => obj.Id != null);
                    if (firstAvailableCard) {
                        return [
                            actions.CreditCardShowForm({ isAdding: false }),
                            actions.SelectActiveCreditCardId({ cardId: firstAvailableCard.Id }), ];
                    }
                }

                if(this._paymentsService.defaultVendorPaymentProvider) {
                    return [
                        actions.CreditCardShowForm({ isAdding: false }),
                        actions.SelectActiveCreditCardId({ cardId: this._paymentsService.defaultVendorPaymentProvider })
                    ];
                }

                if(this._config.payments.payInStore === true) {
                    return [
                        actions.CreditCardShowForm({ isAdding: false }),
                        actions.SelectActiveCreditCardId({ cardId: OLO.Enums.PAYMENT_VENDOR_SERVICE.PAY_IN_STORE })
                    ];
                }

                return [
                    actions.CreditCardShowForm({ isAdding: true }),
                ];
            })
        );

    @Effect() public onCreditCardRemove$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsRemoveRequest),
            switchMap(({ cardId }) => {
                if (cardId !== 0) {
                    return this._creditCardsService.removeMemberCardRequest(cardId)
                        .pipe(
                            map(response =>
                                response ?
                                    actions.CreditCardsRemoveSuccessRequest({ cardId }) :
                                    actions.CreditCardsRemoveErrorRequest({})),
                            catchError(ex => of(actions.CreditCardsRemoveErrorRequest({ ex })))
                        );
                } else {
                    return [actions.CreditCardsRemoveSuccessRequest({ cardId })];
                }
            })
        );

    /* DEMO MODE */
    @Effect() public __DEMO__requestConvergeCardToken$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.__DEMO__getCardToken),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCartLocationNo)
                    )
            ),
            delay(2000),
            switchMap(([action, locationNo]) => {
                const cardType = Utils.CreditCards.detectCardType(action.cardNumber);

                return of(actions.__DEMO__CreditCardsSuccessRequestToken({
                    token: `demo-mode-${new Date().getTime()}`,
                    cardNumber: action.cardNumber,
                    expiryDate: action.expiryDate,
                    cardType,
                    saveCard:
                    action.saveCard
                }));
            })
        );

    @Effect() public __DEMO__onGetConvergeTokenSuccessSaveOrSetupCardStateForPayment$: Observable<any> = this._actions$
        .pipe(
            ofType(actions.__DEMO__CreditCardsSuccessRequestToken),
            switchMap(action => {

                const model: OLO.Members.IMemberCreditCardDetails = {
                    ExpirationDate: action.expiryDate,
                    CardType: action.cardType,
                    Token: action.token,
                    DisplayName: action.cardNumber.substring(action.cardNumber.length - 4),
                    Id: null,
                    ValidationStatus: 'success',
                };

                return [
                    actions.AddCardToState({ card: model }),
                    actions.SelectActiveCreditCardToken({ token: action.token })
                ];

            })
        );

    @Effect() public setErrorValidationFlagToCardsAwaitingValidationStatusSuccess$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CreditCardsAddAfterRedirectErrorRequest,
                actions.CreditCardsAddErrorRequest,
                actions.CreditCardsValidateErrorRequest
            ),
            switchMap(() => of(actions.CreditCardsSetErrorValidationStatusToValidatingCards()))
        );

    @Effect() public triggerRequestLocationAdyenConfig$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CartSetup,
                actions.CartLoad,
                actions.CartSetLocationNo,
                actions.CreditCardsAdyenInit,
                actions.CreditCardsStripeInit
            ),
            filter(() => this._requiresExternalScript()),
            switchMap(() => {
                if(this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN) {
                    return this._store
                        .pipe(
                            select(selectors.getCart),
                            withLatestFrom(
                                this._store
                                    .pipe(
                                        select(selectors.getCardState)
                                    )
                            ),
                            filter(([cart, cards]) => cart.locationNo !== cards.adyen.locationConfig.locationNo
                                && cards.adyen.locationConfig.isDownloading === false),
                            take(1),
                            switchMap(([{ locationNo }]) => [
                                actions.CreditCardsAdyenConfigRequest({ locationNo })
                            ])
                        );
                }

                if(this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.STRIPE) {
                    return this._store
                        .pipe(
                            select(selectors.getCart),
                            withLatestFrom(
                                this._store
                                    .pipe(
                                        select(selectors.getCardState)
                                    )
                            ),
                            filter(([cart, cards]) => cart.locationNo !== cards.stripe.locationConfig.locationNo
                                && cards.stripe.locationConfig.isDownloading === false),
                            take(1),
                            switchMap(([{ locationNo }]) => [
                                actions.CreditCardsStripeConfigRequest({ locationNo })
                            ])
                        );
                }
            })
        );

    @Effect() public getAdyenLocationConfig$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsAdyenConfigRequest),
            switchMap(({ locationNo }) => this._paymentsService.requestAdyenPreconfigurationSetup(locationNo)
                .pipe(
                    map(config => actions.CreditCardsAdyenConfigSuccessRequest({ locationNo, config })),
                    catchError(ex => {
                        console.error('Unable to get Adyen configuration for location');

                        return [
                            actions.CreditCardsAdyenConfigErrorRequest({ locationNo })
                        ];
                    })
                )
            )
        );

    @Effect() public getStripeLocationConfig$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsStripeConfigRequest),
            switchMap(({ locationNo }) => this._paymentsService.requestStripePreconfigurationSetup(locationNo)
                .pipe(
                    map(config => actions.CreditCardsStripeConfigSuccessRequest({ locationNo, config })),
                    catchError(ex => {
                        console.error('Unable to get Stripe configuration for location');

                        return [
                            actions.CreditCardsStripeConfigErrorRequest({ locationNo })
                        ];
                    })
                )
            )
        );

    private _requiresExternalScript(): boolean {
        return this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN
            || this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.STRIPE;
    }

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<StateModels.IStateShared>,
        private _actions$: Actions,
        private _creditCardsService: Services.CreditCardsService,
        private _paymentsService: Services.PaymentsService,
    ) { }

}
