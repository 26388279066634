import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, ICreditCards } from '../interface';
import * as Utils from '@shared/core/utils';

const state = createFeatureSelector<IStateShared, ICreditCards>('creditCards');

export const getCards = createSelector(
    state,
    creditCards => creditCards.data || []
);

export const getCardState = createSelector(
    state,
    creditCards => creditCards
);

export const isCardListEmpty = createSelector(
    state,
    creditCards => creditCards.data !== null ? creditCards.data.length === 0 : false
);

export const showAddCardForm = createSelector(
    state,
    creditCards => creditCards.showAddCardForm
);

export const isAddingCardRequest = createSelector(
    state,
    creditCards => creditCards.add.isAdding || creditCards.token.isGettingToken,
);

export const isLoadingCards = createSelector(
    state,
    creditCards => creditCards.add.isAdding
        || creditCards.token.isGettingToken
        || creditCards.remove.isRemoving
        || creditCards.download.isDownloading
        || creditCards.validation.isValidating
        || creditCards.activeCardRedirectUrl !== null
);

export const getActiveCardRedirectUrl = createSelector(
    state,
    creditCards => creditCards.activeCardRedirectUrl
);

export const getActiveCardId = createSelector(
    state,
    creditCards => creditCards.activeCardId
);

export const getActiveCardToken = createSelector(
    state,
    creditCards => creditCards.activeCardToken
);


export const getSessionToken = createSelector(
    state,
    creditCards => creditCards.sessionToken
);

export const getCreditCardIsRequestInError = createSelector(
    state,
    creditCards => (
        creditCards.add.hasFailed ||
            creditCards.download.hasFailed ||
            creditCards.remove.hasFailed ||
            creditCards.token.hasFailed ||
            creditCards.validation.hasFailed
    )
);

export const getActiveCardDetails = createSelector(
    state,
    creditCards => {
        const activeId: number = creditCards.activeCardId;
        const activeToken: string = creditCards.activeCardToken;
        const cards: OLO.Members.IMemberCreditCardDetails[] = creditCards.data;

        if (!activeId && !activeToken || !cards || cards.length === 0) return null;

        if (activeId) {
            return cards.find(obj => obj.Id === activeId) || null;
        }

        return cards.find(obj => obj['Token'] === activeToken) || null; /* Check TOKEN property - something is not right here - or is it just demo? */

    }
);

export const shouldShowCreditCardForm = createSelector(
    state,
    creditCards => {
        const activeId: number = creditCards.activeCardId;
        const activeToken: string = creditCards.activeCardToken;
        const cards: OLO.Members.IMemberCreditCardDetails[] = creditCards.data;

        if(
            activeId === OLO.Enums.PAYMENT_VENDOR_SERVICE.APPLE_PAY ||
            activeId === OLO.Enums.PAYMENT_VENDOR_SERVICE.GOOGLE_PAY ||
            activeId === OLO.Enums.PAYMENT_VENDOR_SERVICE.ACCOUNT_CHARGE ||
            activeId === OLO.Enums.PAYMENT_VENDOR_SERVICE.PAY_IN_STORE
        ) return false;

        /* Don't reset form manualy */
        if (cards.length !== 0 && creditCards.validation.hasFailed === true) return false;

        if (!activeId && !activeToken || !cards || cards.length === 0) return true;

        if (activeId) {
            const foundCard = cards.find(obj => obj.Id === activeId);

            return foundCard ? false : true;
        }

        if (!activeToken) {
            return true;
        }

        const card = cards.find(obj => obj['Token'] === activeToken); /* Check TOKEN property - something is not right here - or is it just demo? */

        return card ? false : true;

    }
);

export const isCardSelected = (creditCard: OLO.Members.IMemberCreditCardDetails) => createSelector(
    state,
    creditCards => {
        if (!creditCard) return false;

        const activeId: number = creditCards.activeCardId;
        const activeToken: string = creditCards.activeCardToken;

        if (creditCard.Id && activeId) {
            return creditCard.Id === activeId;
        }

        if (creditCard.Token && activeToken) {
            return creditCard.Token === activeToken;
        }

        return creditCard.Id === null && activeId === null && creditCard.Token === null && activeToken === null;
    }
);


export const isDownloadingCardsList = createSelector(
    state,
    creditCards => creditCards.download.isDownloading
);

export const isUnsavedCardSelected = createSelector(
    state,
    creditCards => {
        if (!creditCards.activeCardToken || !creditCards.data || creditCards.data.length === 0) return false;

        return creditCards.data.some(obj => obj.Id === null && obj.Token === creditCards.activeCardToken);
    }
);

export const hasCreditCardsDefined = createSelector(
    state,
    creditCards => {
        if (!creditCards.data || creditCards.data.length === 0) return false;

        return true;
    }
);

export const isRequestingCardsFirstTime = createSelector(
    state,
    creditCards => creditCards.data.length === 0 && creditCards.download.isDownloading && creditCards.download.downloadedDate === null
);

export const hasDownloadedCards = createSelector(
    state,
    creditCards => creditCards.download.downloadedDate !== null
);

export const isRemovingCard = (cardId: number) => createSelector(
    state,
    creditCards => creditCards.remove.isRemoving && creditCards.remove.id === cardId
);

export const removeFailedForCard = (cardId: number) => createSelector(
    state,
    creditCards => creditCards.remove.hasFailed && creditCards.remove.id === cardId
);

export const isAccountChargeSelected = createSelector(
    state,
    creditCards => creditCards.activeCardId === OLO.Enums.PAYMENT_VENDOR_SERVICE.ACCOUNT_CHARGE,
);

export const isPayInStoreSelected = createSelector(
    state,
    creditCards => creditCards.activeCardId === OLO.Enums.PAYMENT_VENDOR_SERVICE.PAY_IN_STORE,
);

export const isPaymentDisabledForCards = createSelector(
    getCardState,
    isLoadingCards,
    (cardsState, cardsLoading) => cardsState.showAddCardForm === true ||
            (
                cardsState.data && cardsState.data.length === 0
                && (cardsState.activeCardId !== OLO.Enums.PAYMENT_VENDOR_SERVICE.ACCOUNT_CHARGE && cardsState.activeCardId !== OLO.Enums.PAYMENT_VENDOR_SERVICE.PAY_IN_STORE)
            ) ||
            !cardsState.data ||
            cardsLoading
);

export const getAdyenLocationConfig = createSelector(
    getCardState,
    cardsState => cardsState.adyen.locationConfig
);

export const getStripeLocationConfig = createSelector(
    getCardState,
    cardsState => cardsState.stripe.locationConfig
);

export const isCreditCardPaymentMethodSelected = createSelector(
    getCardState,
    cardsState => cardsState.activeCardId !== null || cardsState.activeCardToken !== null
);

export const isApplePayPaymentMethodSelected = createSelector(
    getActiveCardId,
    cardId => cardId === OLO.Enums.PAYMENT_VENDOR_SERVICE.APPLE_PAY
);

export const isGooglePayPaymentMethodSelected = createSelector(
    getActiveCardId,
    cardId => cardId === OLO.Enums.PAYMENT_VENDOR_SERVICE.GOOGLE_PAY
);
