import { OrderPaymentMethod, OrderPaymentDetails } from './order-payment-method';

export class OrderPaymentWithStripe extends OrderPaymentMethod {
    public generatePaymentDetails(details: OrderPaymentDetails) {
        const { paymentProvider, cardState, paymentMethod } = details;
        const cardDetails = this._getCardDetails(cardState);

        if(!this._isVendorPayment(paymentMethod) && paymentProvider === OLO.Enums.PAYMENT_PROVIDER.STRIPE
            && (cardState.activeCardId || cardState.activeCardToken)
        ) {
            this._extendExecutePaymentModel(details);

            if (cardState.activeCardToken) {
                this._executePaymentModel.Token = cardDetails.Token;
                this._executePaymentModel.PaymentAccountId = null;
                this._executePaymentModel.StripePaymentDetails = {
                    CardType: cardDetails.CardType,
                    SaveForLaterUse: cardDetails?.SaveAwait || false,
                    Last4Digits: cardDetails.StripePaymentData?.card?.last4 || null,
                    ExpirationDate: cardDetails.ExpirationDate,
                    IsDefault: cardDetails.IsDefault || false
                };
            }

            return this._executePaymentModel;
        }


        if(this._next) {
            return this._next.generatePaymentDetails(details);
        }

        return this._executePaymentModel;
    }
}
