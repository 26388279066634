import {
    IExecuteAccountChargeResponse,
    IExecuteOnlineOrderAccountChargeModel,
    IExecutePaymentModel,
    IExecutePaymentResponse,
    IGetTransactionResponse,
} from '@shared/state';

export class PaymentsMapper {
    public static mapPayWithAccountChargePOSTRequest(payWithAccount: IExecuteOnlineOrderAccountChargeModel): APIv3.ExecuteOnlineOrderAccountChargeModel {
        return payWithAccount ? {
            Amount: payWithAccount.Amount,
            SetOrderAsValidatedOnSuccess: payWithAccount.SetOrderAsValidatedOnSuccess,
        } : null;
    }

    public static mapPayWithAccountChargePOSTResponse(payWithAccount: APIv3.ExecuteAccountChargeResponse): IExecuteAccountChargeResponse {
        return payWithAccount ? {
            Status: payWithAccount.Status,
        } : null;
    }

    public static mapPayPOSTRequest(pay: IExecutePaymentModel): APIv3.ExecutePaymentModel {
        return pay ? {
            Amount: pay.Amount,
            PaymentAccountId: pay.PaymentAccountId,
            SetOrderAsValidatedOnSuccess: pay.SetOrderAsValidatedOnSuccess,
            PaymentProvider: pay.PaymentProvider as 1 | 2 | 3 | 4 | 5 | 6,
            Token: pay.Token,
            ExpirationDate: pay.ExpirationDate,
            StripePaymentDetails: pay.StripePaymentDetails ? {
                CardType: pay.StripePaymentDetails.CardType,
                ExpirationDate: pay.StripePaymentDetails.ExpirationDate,
                IsDefault: pay.StripePaymentDetails.IsDefault,
                Last4Digits: pay.StripePaymentDetails.Last4Digits,
                SaveForLaterUse: pay.StripePaymentDetails.SaveForLaterUse,
            } : null,
            ApplePayToken: pay.ApplePayToken ?
                {
                    PaymentData: pay.ApplePayToken.PaymentData,
                    TransactionIdentifier: pay.ApplePayToken.TransactionIdentifier,
                } : null,
            GooglePayToken: pay.GooglePayToken ?
                {
                    PaymentData: pay.GooglePayToken.PaymentData,
                } : null,
            FatZebraToken: pay.FatZebraToken ?
                {
                    r: pay.FatZebraToken.r,
                    v: pay.FatZebraToken.v,
                } : null,
            CardData: pay.CardData ?
                {
                    EncryptedCardNumber: pay.CardData.EncryptedCardNumber,
                    EncryptedExpiryMonth: pay.CardData.EncryptedExpiryMonth,
                    EncryptedExpiryYear: pay.CardData.EncryptedExpiryYear,
                    EncryptedSecurityCode: pay.CardData.EncryptedSecurityCode,
                    SaveForFuture: pay.CardData.SaveForFuture
                } : null,
        } : null;
    }

    public static mapPayPOSTResponse(pay: APIv3.ExecutePaymentResponse): IExecutePaymentResponse {
        return pay ? {
            TransactionId: pay.TransactionId,
        } : null;
    }

    public static maPaymentStatusGETResponse(paymentStatus: APIv3.GetTransactionResponse): IGetTransactionResponse {
        return paymentStatus ? {
            TransactionId: paymentStatus.TransactionId,
            Status: paymentStatus.Status,
        } : null;
    }
}
