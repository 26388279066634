import * as State from '@shared/state';

export interface OrderPaymentDetails {
    paymentProvider: OLO.Enums.PAYMENT_PROVIDER;
    paymentMethod: OLO.Ordering.IPaymentMethod;
    onlineOrder: State.IOnlineOrderDetailedBusinessModel;
    cardState: State.ICreditCards;
};

/**
 * Generates payment details based on logic provided in `generatePaymentDetails` method
 */
export abstract class OrderPaymentMethod {
    protected _next: OrderPaymentMethod = null;
    protected _executePaymentModel: State.IExecutePaymentModel = {
        SetOrderAsValidatedOnSuccess: true,
        Amount: null,
    } as State.IExecutePaymentModel;

    /**
     * Sets required properties to final `_executePaymentModel`
     * @param {OrderPaymentDetails} details order and payment details object
     * @returns {void} void
     */
    protected _extendExecutePaymentModel(details: OrderPaymentDetails): void {
        const { onlineOrder, cardState, paymentProvider } = details;

        this._executePaymentModel.Amount = onlineOrder.TotalLeftToPay;
        this._executePaymentModel.PaymentAccountId = cardState.activeCardId;
        this._executePaymentModel.Token = cardState.activeCardToken;
        this._executePaymentModel.PaymentProvider = paymentProvider;
    }

    /**
     * Extracts details about current active card from `cardState`
     * @param {State.ICreditCards} cardState
     * @returns {OLO.Members.IMemberCreditCardDetails} credit card
     */
    protected _getCardDetails(cardState: State.ICreditCards): OLO.Members.IMemberCreditCardDetails {
        return cardState.data?.find(obj => cardState.activeCardToken ?
            (obj.Token === cardState.activeCardToken) :
            (cardState.activeCardId === obj.Id)
            );
    }

    /**
     * Based on paymentMethod details, check if payment is set to vendor - google or apple
     * @param {OLO.Ordering.IPaymentMethod} paymentMethod
     * @returns {boolean} boolean
     */
    protected _isVendorPayment(paymentMethod: OLO.Ordering.IPaymentMethod): boolean {
        return paymentMethod?.vendorService === OLO.Enums.PAYMENT_VENDOR_SERVICE.GOOGLE_PAY
            && Boolean(paymentMethod?.googlePaymentData?.PaymentData)
            || paymentMethod?.vendorService === OLO.Enums.PAYMENT_VENDOR_SERVICE.APPLE_PAY
            && Boolean(paymentMethod?.applePaymentData?.PaymentData);
    }

    /**
     * Sets next order payment details handler if the current does not match
     * @param {OrderPaymentMethod} next order payment details handler
     * @returns {void} void
     */
    public setNext(next: OrderPaymentMethod): void {
        this._next = next;
    }

    /**
     * Calculates order payment details object for payment
     * @param {OrderPaymentDetails} details order and payment details object
     * @returns {ExecutePaymentModels} payment details object used for payment process
     */
    public generatePaymentDetails(details: OrderPaymentDetails): State.IExecutePaymentModel {
        throw new Error('Method needs to be overwritten');
    }
}
